import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
import 'rxjs/add/operator/map';
import * as Globales from '../globales';

@Injectable()
export class LoginService {
  public token: string;
  public usuario: string;

  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const client_id = '099153c2625149bc8ecb3e85e03f0022';

    const body = `username=${username}&password=${password}&client_id=${client_id}&grant_type=password`;
    return this.http
      .post(Globales.API_URL + 'token', body.toString(), { headers: headers })
      .map(response => {
        const token = response && response['access_token'];

        if (token) {
          // seteo
          this.token = token;
          localStorage.setItem('id_token', token);

          return true;
        } else {
          console.log('false');
          return false;
        }
      });
  }

  logout(): void {
    this.token = null;
    localStorage.removeItem('id_token');
  }

  public getToken(): string {
    return localStorage.getItem('id_token');
  }

  getUsuario() {
    return this.http
      .get<string>(Globales.API_URL + 'Empleado/getUsuario')
      .subscribe(response => response);
  }

  getIdAccessToken(cedula: string, clave: string, tokenReCAPTCHA: string) {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
            
	var body = JSON.stringify({ cedula: cedula, clave: clave, tokenReCAPTCHA: tokenReCAPTCHA });
	
    return this.http
    .post(Globales.API_URL + 'acceso/login', body, { headers: headers })
    .map(response => {

      const token = response && response['Token'];
      let status: boolean = false;
      if (token) {
        this.token = token;
        localStorage.setItem('id_access_token', token);
        status = true;
      } else {
        status = false;
      }

      return { estado: status, mensaje: response['Error'] }

    });
  }

  getStringIdAccessToken(): string {
    return localStorage.getItem('id_access_token');
  }


  getLogin(): string {
    const token = this.getToken();
    const usr = decode(token).sub;
    return usr;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    const expiryDate = new Date(0);
    const exp = decode(token).exp;
    expiryDate.setUTCSeconds(exp);
    return expiryDate.valueOf() > new Date().valueOf();
  }

  muestraMenu(empleado) {
    return this.http.get(`${Globales.API_URL}PerfilUsuario/${empleado}`);
  }
}
