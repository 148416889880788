import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { LoginService } from '../services/login.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: LoginService) {}

  canActivate() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
